import * as React from 'react';
import { Card, Button, Spinner, Fade, Form, Col, Alert, Table} from 'react-bootstrap';
import QRCode from "qrcode.react";
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from '../amplifyConfig';

Amplify.configure(awsconfig);

class MfaSetting extends React.Component {
  constructor(){
    super();
    this.state = {
      TOTPisProcess: false,
      SMSisProcess: false,
      TOTPstatus: false,
      SMSstatus: false,
      TOTPisProcessSuccess: false,
      SMSisProcessSuccess: false,
      TOTPopen: false,
      SMSopen: false,
      isProcess: false,
      AuthCodeOpen: false,
      isProcessSuccess: false,
      messageShow: false,
      MFAisProcess: false,
      userSession: '',
      accessToken: '',
      idToken: '',
      secretCode: '',
      AuthCode: '',
      MFAstatus: '',
    }
    this.TOTPhandleUpdate = this.TOTPhandleUpdate.bind(this);
    this.SMShandleUpdate = this.SMShandleUpdate.bind(this);
    this.MFAhandleUpdate = this.MFAhandleUpdate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleMessageClose = this.handleMessageClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSession();
  }

  /**
   * TOTP設定
   */
  async TOTPhandleUpdate() {

    this.setState({ TOTPisProcess : true });

    const path = '/mfa/assignAuthCode';
    const params = {
      accessToken: this.state.accessToken,
      status: 'TOTP',
    };

    try{
      const secretCode = await postData(path,params,this.state.idToken);
      this.setState({ secretCode: secretCode });
      this.setState({ TOTPisProcessSuccess: true });
    }catch(e){
      console.log(e);
      this.setState({ TOTPisProcessSuccess: false });
    }
    this.state.TOTPisProcessSuccess ? this.setState({ TOTPopen : true }) : this.setState({ TOTPopen : false });
    this.state.TOTPisProcessSuccess ? this.setState({ AuthCodeOpen : true }) : this.setState({ AuthCodeOpen : false });
    
    this.setState({ TOTPisProcess : false });
  }

  /**
   * SMS設定
   */
  async SMShandleUpdate() {

    this.setState({ SMSisProcess : true });

    const path = '/mfa/assignAuthCode';
    const params = {
      accessToken: this.state.accessToken,
      status: 'SMS',
    };

    try{
      await postData(path,params,this.state.idToken);
      this.setState({ SMSisProcessSuccess: true });
    }catch(e){
      console.log(e);
      this.setState({ SMSisProcessSuccess: false });
    }
    
    this.state.SMSisProcessSuccess ? this.setState({ SMSopen : true }) : this.setState({ SMSopen : false });
    this.state.SMSisProcessSuccess ? this.setState({ AuthCodeOpen : true }) : this.setState({ AuthCodeOpen : false });
    this.setState({ SMSisProcess : false });
  }

  /**
   * MFA解除
   */
  async MFAhandleUpdate(){

    this.setState({ MFAisProcess : true });

    const path = '/mfa/invalidate';
    const params = {
      accessToken: this.state.accessToken,
      status: this.state.MFAstatus,
    };

    await postData(path,params,this.state.idToken);

    this.setState({ 
      TOTPstatus : false,
      TOTPisProcessSuccess : false,
      SMSstatus : false,
      SMSisProcessSuccess : false
    });

    this.setState({ MFAisProcess : false });
  }

  /**
   * 登録ボタン
   */
  async handleUpdate(){
 
    this.setState({ isProcess : true });

    const path = '/mfa/activate';
    const params = {
      accessToken: this.state.accessToken,
      //TOTPとSMSのどちらでMFA設定を行っているかをstate持たせても良さそう。
      status: this.state.TOTPisProcessSuccess ? 'TOTP' : 'SMS',
      code: this.state.AuthCode,
    };

    try{
      await postData(path,params,this.state.idToken);
      this.setState({ isProcessSuccess: true});
    }catch(e){
      console.log(e);
      this.setState({ isProcessSuccess: false});
    }

    this.setState({ messageShow : true});

    //TOTP
    this.state.isProcessSuccess ? 
      this.state.TOTPisProcessSuccess ? 
      this.setState({ 
        TOTPstatus : true,
        TOTPopen : false,
        AuthCodeOpen : false
      }) 
      : this.setState({ TOTPstatus : false })
    : this.setState({ TOTPstatus : false });

    //SMS
    this.state.isProcessSuccess ? 
      this.state.SMSisProcessSuccess ? 
      this.setState({ 
        SMSstatus : true,
        SMSopen : false,
        AuthCodeOpen : false
      }) 
      : this.setState({ SMSstatus : false }) 
    : this.setState({ SMSstatus : false });

    this.setState({ isProcess: false });
  }

  /**
   * メッセージを閉じる
   */
  handleMessageClose() {
    this.setState({ messageShow : false });
  }

  async getSession(){
    const session = await Auth.currentSession();
    this.setState({ 
      userSession: session,
      accessToken: session.accessToken.jwtToken,
      idToken: session.idToken.jwtToken
    });
    
    const path = '/user/get';
    const params = {
      accessToken: this.state.accessToken,
      user_pool: awsconfig.Auth.userPoolId,
    };

    const response = await postData(path,params,this.state.idToken);
    console.log(response);

    this.setState({ MFAstatus : response['MFAsetting'] });

    this.state.MFAstatus ? this.state.MFAstatus === 'SOFTWARE_TOKEN_MFA' ? 
    this.setState({ TOTPstatus : true })
    :this.setState({ SMSstatus : true})
    : this.setState({ SMSstatus : false, TOTPstatus : false})
  }

  async handleChange(event){
    this.setState({ AuthCode: event.target.value});
  }

  render() {
    return (
      <Card show={this.props.isModalOpen}>
        <Card.Header >
          MFA設定 { this.state.TOTPisProcess || this.state.TOTPopen ? '-> TOTP設定' : '' }{ this.state.SMSisProcess || this.state.SMSopen ? '-> SMS設定' : '' }
         </Card.Header>
        <Card.Body>

          {/* MFAステータス表示をどの様な感じで表示させるか検討する */}
          <Form id="MFAstate">
            <Col xs={8} sm={5}>
              <Table bordered>
                <tbody>
                  <tr>
                    <td sm={2}>設定状況</td>
                    <td>{this.state.TOTPstatus ? 'TOTP設定済み' : 
                         this.state.SMSstatus ? 'SMS設定済み' : '未設定'}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Form>
          { this.state.TOTPopen || this.state.SMSopen ? '' : this.state.TOTPstatus || this.state.SMSstatus ?
            '' : !this.state.TOTPisProcess ? 
              <Button variant="primary" size="lg" block onClick={this.TOTPhandleUpdate}>TOTP設定</Button> : 
              <Button variant="primary" size="lg" block disabled>
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              />
              &nbsp;処理中... 
              </Button>
          }
          { this.state.TOTPopen || this.state.SMSopen ? '' : this.state.TOTPstatus || this.state.SMSstatus ?
            '' : !this.state.SMSisProcess ? 
            <Button variant="primary" size="lg" block onClick={this.SMShandleUpdate}>SMS設定</Button> : 
            <Button variant="primary" size="lg" block disabled>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            />
            &nbsp;処理中...
          </Button>
          }
          { this.state.TOTPstatus || this.state.SMSstatus ? !this.state.MFAisProcess ? 
            <Button variant="primary" size="lg" block onClick={this.MFAhandleUpdate}>MFA解除</Button> : 
            <Button variant="primary" size="lg" block disabled>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            />
            &nbsp;処理中... 
            </Button> : ''
          }

          <Fade in={this.state.TOTPopen} style={this.state.TOTPopen ? {display:'block'} : {display:'none'}}>
            <Form id="TOTPregistMsg">
            <Card>
              <Card.Body>
                <Form.Label column>
                  QRコードを発行しました。
                </Form.Label>
                    
                <Form.Group controlId="formQR">
                  <QRCode value={this.state.secretCode} />
                </Form.Group>
                <Fade in={this.state.AuthCodeOpen} style={this.state.AuthCodeOpen ? {display:'block'} : {display:'none'}}>
                  <Form id="AuthCodeForm1">
                    <Form.Label column>
                      認証コード
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="code" onChange={this.handleChange}/>
                    </Col>
                  </Form>
                </Fade>
              </Card.Body>
            </Card>
            </Form>
          </Fade>

          <Fade in={this.state.SMSopen} style={this.state.SMSopen ? {display:'block'} : {display:'none'}}>
            <Form id="SMSregistMsg">
            <Card>
              <Card.Body>
                <Form.Label column>
                  登録済み電話番号にメッセージを送信しました。
                </Form.Label>
                <Fade in={this.state.AuthCodeOpen} style={this.state.AuthCodeOpen ? {display:'block'} : {display:'none'}}>
                  <Form id="AuthCodeForm2">
                    <Form.Label column>
                      認証コード
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="code" onChange={this.handleChange}/>
                    </Col>
                  </Form>
                </Fade>
              </Card.Body>
            </Card>
            </Form>
          </Fade>
        </Card.Body>
        
        <Card.Footer>
          <Fade in={this.state.AuthCodeOpen} style={this.state.AuthCodeOpen ? {display:'block'} : {display:'none'}}>
          { !this.state.isProcess ?
            <Button variant="primary" onClick={this.handleUpdate}>登録</Button> :
            <Button variant="primary" disabled>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            />
            &nbsp;処理中...
            </Button>
          }
          </Fade>
          { this.state.isProcessSuccess ? 
          <Alert show={this.state.messageShow} variant="success" onClose={this.handleMessageClose} dismissible>
            登録が完了しました。
          </Alert>
          :
          <Alert show={this.state.messageShow} variant="danger" onClose={this.handleMessageClose} dismissible>
            登録に失敗しました。
          </Alert>
          }
        </Card.Footer>
      </Card>
    )
  }
}
export default MfaSetting;

async function postData(url,param,idToken) { 
  const apiName = 'api_url_base';
  const path = url;
  const myInit = { 
      method : 'POST',
      body: param, 
      headers: {
        'Content-type' : 'application/json',
        Authorization: idToken,
      }
  };

  return await API.post(apiName, path, myInit);
}