import * as React from 'react';
import { Button, Navbar, Nav} from 'react-bootstrap';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../amplifyConfig';

Amplify.configure(awsconfig);

class AppNavBar extends React.Component {
    render() {
      return (
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>マイアカウント</Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link href="/" >プロフィール</Nav.Link>
            <Nav.Link href="/mfa" >MFA設定</Nav.Link>
            <Nav.Link href="/passwd" >パスワード設定</Nav.Link>
          </Nav>
          {/* <AmplifySignOut /> */}
          <Button variant="secondary" onClick={() => Auth.signOut({ global : true })}>ログアウト</Button>
        </Navbar>
      );
    }
  }
  
  export default AppNavBar;