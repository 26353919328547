import  React from 'react';
import { Form, Button, Row, Col, Card, Spinner, Alert, FormGroup} from 'react-bootstrap';
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from '../amplifyConfig';

Amplify.configure(awsconfig);

class ProfileSetting extends React.Component {
  constructor(){

      super();
      this.state = {
        isProcess: false,
        messageShow: false,
        isProcessSuccess: false,
        userSession: '',
        accessToken: '',
        idToken: '',
        userId: '',
        tenantId: '',
        username: '',
        name: '',
        email: '',
        phoneNumber: '',
        usernameValid: false,
        nameValid: false,
        emailValid: false,
        phoneNumberValid: false,
        MFAstatus: '',
      }
      this.handleUpdate = this.handleUpdate.bind(this);
      this.handleMessageClose = this.handleMessageClose.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.getUser();
  }

  async handleUpdate() {
    this.setState({ isProcess: true });

    if(chkHissu() !== false){
      const mfaSetting = this.state.MFAstatus;
      if(mfaSetting === "SMS_MFA"){
        console.log('smsMFA設定がしてあります');
      }else{
        var chk_email  = chkEmail(this.state.email);
        var chk_number = chkNum(this.state.phoneNumber);
        var chk_name   = chkName(this.state.name);

        if((chk_email && chk_number && chk_name) === true){
          var phoneNumber = this.state.phoneNumber;
          phoneNumber = phoneNumber.replace('0','+81');

          const path = '/user/update';
          const params = {
            username: this.state.username,
            name: this.state.name,
            email: this.state.email,
            tell: phoneNumber,
            accessToken: this.state.accessToken,
          };

          try{
            await postData(path,params,this.state.idToken);
            this.setState({ isProcessSuccess: true });
          }catch(e){
            console.log(e);
            this.setState({ isProcessSuccess: false });
          }

          this.setState({ messageShow: true });
          this.setState({ isProcess: false });
        }else{
          console.log('入力チェックエラー');
          if(chk_name === false){
            this.setState({
              isProcessSuccess: false,
              messageShow: true,
              isProcess: false,
              nameValid: false});
          }
          if(chk_email === false){
            this.setState({
              isProcessSuccess: false,
              messageShow: true,
              isProcess: false,
              emailValid: false});
          }
        }
      }
    }else{
      console.log('必須項目エラー');
      this.setState({
        isProcessSuccess: false,
        messageShow: true,
        isProcess: false,
      });
    }
  }

  handleMessageClose() {
      this.setState({ messageShow: false });
  }

  async handleChange(event){
    const name = event.target.id
    this.setState({[name]: event.target.value});
    
    if(name === "name"){
      chkName(event.target.value) ?
      this.setState({[name + 'Valid']:true})
      : this.setState({[name + 'Valid']:false});
    }

    if(name === "email"){
      chkEmail(event.target.value) ?
      this.setState({[name + 'Valid']:true})
      : this.setState({[name + 'Valid']:false});
    }

    if(name === "phoneNumber"){
      chkNum(event.target.value) ?
      this.setState({[name + 'Valid']:true})
      : this.setState({[name + 'Valid']:false});
    }
  }

  async getUser(){
    const user = await Auth.currentSession();
    this.setState({ 
      userSession: user,
      accessToken: user.accessToken.jwtToken,
      idToken: user.idToken.jwtToken
    });

    const idToken = this.state.idToken;
    const path = '/user/get';
    const params = {
      accessToken: this.state.accessToken,
      user_pool: awsconfig.Auth.userPoolId,
    };

    this.setState({
      usernameValid : true,
      nameValid : true,
      emailValid : true,
      phoneNumberValid : true,
    });

    const response = await postData(path,params,idToken);
    console.log(response)

    this.setState({ userId: response['custom:userId']});
    this.setState({ tenantId: response['groupName']});
    this.setState({ username: response['custom:dispName']});
    this.setState({ name: response['name']});
    this.setState({ email: response['email']});

    var phoneNumber = response['phone_number'];
    phoneNumber = phoneNumber != null ? phoneNumber.replace('+81','0') : '';
    this.setState({ phoneNumber: phoneNumber});
    this.setState({ MFAstatus: response['MFAsetting']});

  }

  render() {
    return (
        <Card >
        <Card.Header>プロフィール</Card.Header>
        <Card.Body>
            <Form id="formId" noValidate>
              
              <Form.Group as={Row} controlId="userId">
                <Form.Label column sm={4} md={3} lg={2}>
                  ユーザID:
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control type="userId" value={this.state.userId} disabled/>
                </Col>
              </Form.Group>
              
              <Form.Group as={Row} controlId="tenantId">
                <Form.Label column sm={4} md={3} lg={2}>
                  所属テナントID:
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control type="tenantId" value={this.state.tenantId} disabled/>
                </Col>
              </Form.Group>
              
              <Form.Group as={Row} controlId="username">
                <Form.Label column sm={4} md={3} lg={2}>
                  表示名:
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control type="username" value={this.state.username} onChange={this.handleChange} isInvalid={!this.state.usernameValid} isValid={this.state.usernameValid}/>
                </Col>
              </Form.Group>
              
              <FormGroup as={Row} controlId="name">
                <Form.Label column sm={4} md={3} lg={2}>
                  名前(必須):
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control type="name" value={this.state.name} onChange={this.handleChange} isInvalid={!this.state.nameValid} isValid={this.state.nameValid}/>
                </Col>
              </FormGroup>
            
              <Form.Group as={Row} controlId="email">
                <Form.Label column sm={4} md={3} lg={2}>
                  メールアドレス(必須):
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control type="email" value={this.state.email} onChange={this.handleChange} isInvalid={!this.state.emailValid} isValid={this.state.emailValid}/>
                </Col>
              </Form.Group>
            
              <Form.Group as={Row} controlId="phoneNumber">
                <Form.Label column sm={4} md={3} lg={2}>
                  電話番号:
                </Form.Label>
                <Col sm={8} md={9} lg={10}>
                  <Form.Control type="phoneNumber" value={this.state.phoneNumber} onChange={this.handleChange} isInvalid={!this.state.phoneNumberValid} isValid={this.state.phoneNumberValid}/>
                </Col>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid city.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
        </Card.Body>
        <Card.Footer>
          { !this.state.isProcess ? 
            <Button variant="primary" onClick={this.handleUpdate}>変更</Button> : 
            <Button variant="primary" disabled>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            />
            &nbsp;処理中...
          </Button>
          }
          { this.state.isProcessSuccess ? 
          <Alert show={this.state.messageShow} variant="success" onClose={this.handleMessageClose} dismissible>
            更新が完了しました。
          </Alert>
          :
          <Alert show={this.state.messageShow} variant="danger" onClose={this.handleMessageClose} dismissible>
            更新に失敗しました。
          </Alert>
          }
        </Card.Footer>
      </Card>
    )
  }
}
export default ProfileSetting;

async function postData(url,param,idToken) { 
  const apiName = 'api_url_base';
  const path = url;
  const myInit = { 
      method : 'POST',
      body: param, 
      headers: {
        'Content-type' : 'application/json',
        Authorization: idToken,
      }
  };

  return await API.post(apiName, path, myInit);
}

function chkHissu(){
  var hissu = Array("name","email");
  var hissu_nm = Array("名前","メールアドレス");
  var kazu = hissu.length;

  for (var i = 0; i < kazu; i++) {
    var obj = document.getElementById(hissu[i]);
    if(obj.value === ""){
      console.log(hissu_nm[i] + 'は必須です');
      return false;
    }
  }
  return true;
}

function chkName(str){
  //入力された文字数が2048文字以内だったら正しい
  var nameLength=str.length;
  var maxLength = 2048;

  console.log(nameLength);
  if(nameLength > maxLength) {
      /* 入力文字数が最大文字数を超えている場合 */
      console.log("最大文字数を超えています");
      return false;
  }
  if(nameLength === 0){
    return false;
  }
  return true;
}

function chkNum(obj){
  var num="0123456789";
  var tmp=new Array();
  for(var i=0; i<obj.length; i++){
      tmp[i]=obj.substring(i,i+1);
      var flag=num.indexOf(tmp[i]);
      if(flag===-1){
          console.log("数値(0-9)以外が含まれています");
          return false;
      }
  }
  return true;
}

function chkEmail(str){
  // @が含まれていて、最後が .(ドット)でないなら正しいとする
  var Seiki=/[!#-9A-~]+@+[a-z0-9]+.+[^.]$/i;

  if(str!==""){
      if(str.match(Seiki)){
          return true;
      }else{
          console.log("メールアドレスの形式が不正です");
          return false;
      }
  }else{
      console.log("メールアドレスを入力してください");
      return false;
  }
}