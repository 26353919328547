import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MfaSetting from './views/MfaSetting';
import PasswdSetting from './views/PasswdSetting';
import ProfileSetting from './views/ProfileSetting';
import AppNavBar from './views/AppNavBar';
import './App.css';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './amplifyConfig';
import { Card, Form } from 'react-bootstrap';

Amplify.configure(awsconfig);
  
  function App() {
    const [user, setUser] = useState(null);
    const location = window.location.pathname;
    
    useEffect(() => {
      
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'oAuthSignOut':
            // setUser(null);
            console.log('ログアウト');
            break;
          case 'signIn_failure':
            case 'cognitoHostedUI_failure':
              console.log('Sign in failure', data);
              break;
          default : console.log('ログインしていません。');
        }
      });

      if(location !== '/logOut'){
      getUser().then(userData => setUser(userData));
      }
    }, []);
  
    function getUser() {
      return Auth.currentAuthenticatedUser()
        .then(userData => userData)
        .catch(() => Auth.federatedSignIn());
    }
  
    return (
      <div>
      {location === '/logOut' ? (
          <Card>
          <Card.Body>
              <Form id="infoMsg">
                  <Form.Label column sm={8}>
                  ログアウトしました。<br/>完全にログアウト処理が完了するまで少しかかります。
                  </Form.Label>
              </Form>
          </Card.Body>
      </Card>
      ) :user ? (
              <Router>
              <div>
                <AppNavBar />
                {/* <div><Location/></div> */}
                <Switch>
                  <Route exact path='/mfa' component={MfaSetting}/>
                  <Route exact path='/passwd' component={PasswdSetting}/>
                  <Route path='/' component={ProfileSetting}/>
                </Switch>
              </div>
            </Router>
       ) : (
        ''
      ) } 
      </div>
    );
  }

// function Location(){
//   const location = window.location.pathname;
//   return location;
// }

export default App;