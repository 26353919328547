import * as React from 'react';
import { Card, Button, Spinner, Fade, Form, Col, Alert} from 'react-bootstrap';
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from '../amplifyConfig';

Amplify.configure(awsconfig);

class PasswdSetting extends React.Component {
  constructor(){
    super();
    this.state = {
      isProcess: false,
      isProcessSuccess: false,
      newPassSet: false,
      messageShow: false,
      userSession: '',
      accessToken: '',
      idToken: '',
      email: '',
      newPass: '',
      code: '',
      newPassValid: false,
    }
    this.handleUpdate = this.handleUpdate.bind(this);
    this.RegistUpdate = this.RegistUpdate.bind(this);
    this.handleMessageClose = this.handleMessageClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSession();
  }

  async handleUpdate(){

    this.setState({ isProcess: true});

    const path = '/pass/assignAuthCode';
    const params = {
      username: this.state.email,
      client_id: awsconfig.Auth.userPoolWebClientId
    };

    await postData(path,params,this.state.idToken);

    this.setState({ newPassSet: true});
    this.setState({ isProcess: false });
  }

  async RegistUpdate(){

    this.setState({ isProcess: true});

    const path = '/pass/reset';
    const params = {
      username: this.state.email,
      client_id: awsconfig.Auth.userPoolWebClientId,
      newPass: this.state.newPass,
      code: this.state.code,
    };

    try{
      await postData(path,params,this.state.idToken);
      this.setState({ isProcessSuccess: true});
    }catch(e){
      this.setState({ isProcessSuccess: false});
    }
    
    this.setState({ messageShow: true});
    this.setState({ isProcess: false});
  }

  handleMessageClose() {
    this.setState({ messageShow: false});
  }

  async getSession(){
    const session = await Auth.currentSession();
    this.setState({ 
      userSession: session,
      accessToken: session.accessToken.jwtToken,
      idToken: session.idToken.jwtToken
    });

    const path = '/user/get';
    const params = {
      accessToken: this.state.accessToken,
      user_pool: awsconfig.Auth.userPoolId,
    };

    const response = await postData(path,params,this.state.idToken);
    console.log(response);
    this.setState({ email : response['email']});
  }

  async handleChange(event){
    const name = event.target.id
    this.setState({[name]: event.target.value});

    if(name === "newPass"){
      chkpass(event.target.value) ?
      this.setState({[name + 'Valid']:true})
      : this.setState({[name + 'Valid']:false});
    }
  }

  render() {
    return (
        <Card>
        <Card.Header >
            パスワードリセット
        </Card.Header>
        <Card.Body>
          <Form id="infoMsg">
            <Fade in={!this.state.newPassSet} style={!this.state.newPassSet ? {display:'block'} : {display:'none'}}>
              <Form.Label column sm={8}>
                登録されているメールアドレスにメールが送信されます。<br />よろしいですか。
              </Form.Label>
            </Fade>

            <Fade in={this.state.newPassSet} style={this.state.newPassSet ? {display:'block'} : {display:'none'}}>
              <Form id="AuthCodeForm">
                <Form.Label column sm={8}>
                  メールを送信しました。
                </Form.Label>
                
                <Form.Group controlId="newPass">
                  <Form.Label column sm={3}>
                    新規パスワード
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control type="newPass" onChange={this.handleChange} isInvalid={!this.state.newPassValid} isValid={this.state.newPassValid}/>
                  </Col>
                </Form.Group>
              
                <Form.Group controlId="code">
                <Form.Label column sm={3}>
                  認証コード
                </Form.Label>
                  <Col sm={2}>
                    <Form.Control type="code" onChange={this.handleChange}/>
                  </Col>
                  </Form.Group>
              </Form>
            </Fade>
          </Form>
        </Card.Body>
        <Card.Footer>
        <Fade in={!this.state.newPassSet} style={!this.state.newPassSet ? {display:'block'} : {display:'none'}}>
          { !this.state.isProcess ?
            <Button variant="primary" onClick={this.handleUpdate}>はい</Button> :
            <Button variant="primary" disabled>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            />
            &nbsp;処理中...
            </Button>
          }
          </Fade>

          <Fade in={this.state.newPassSet} style={this.state.newPassSet ? {display:'block'} : {display:'none'}}>
          { !this.state.isProcess ?
            <Button variant="primary" onClick={this.RegistUpdate} disabled={this.state.isProcessSuccess ? true : false}>登録</Button> :
            <Button variant="primary" disabled>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            />
            &nbsp;処理中...
            </Button>
          }
          </Fade>
          { this.state.isProcessSuccess ? 
          <Alert show={this.state.messageShow} variant="success" onClose={this.handleMessageClose} dismissible>
            登録が完了しました。
          </Alert>
          :
          <Alert show={this.state.messageShow} variant="danger" onClose={this.handleMessageClose} dismissible>
            登録に失敗しました。
          </Alert>
          }
        </Card.Footer>
        {}
    </Card>
    )
  }
}

export default PasswdSetting;

async function postData(url,param,idToken) { 
  const apiName = 'api_url_base';
  const path = url;
  const myInit = { 
      method : 'POST',
      body: param, 
      headers: {
        'Content-type' : 'application/json',
        Authorization: idToken,
      }
  };

  return await API.post(apiName, path, myInit);
}

function chkpass(obj){
  var passLength=obj.length;
  var maxLength = 99;
  var minLength = 8;
  var iChars = "~`!#$%^&*+=-[]\\';,/{}|\":<>?"; //Cognitoでパスワードに使用できる特殊文字
  var num="0123456789";
  var tmp1 = new Array();
  var tmp2 = new Array();
  var tmp3 = new Array();
  var STR = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  console.log(passLength);
  if(passLength > maxLength) {
     /* 入力文字数が最大文字数を超えている場合 */
     console.log("最大文字数を超えています");
     return false;
  }else if(passLength <= minLength){
     /* 入力文字数が最小文字数より少ない場合 */
     console.log("最小文字数より少ないです");
     return false;
  }

   for(var i = 0; i < passLength; i++) {

    tmp1[i] = obj.charAt(i);
    var flag1 = iChars.indexOf(tmp1[i]);

      if(flag1 !== -1){
        for (var j = 0; j < passLength; j++) {
          tmp2[j]=obj.substring(j,j+1);
          var flag2 = num.indexOf(tmp2[j]);

          if(flag2 !== -1){
             for(var n = 0; n < passLength; n++){
               tmp3[n] = obj.substring(n,n+1);
               var flag3 = STR.indexOf(tmp3[n]);
               if(flag3 !== -1){
                 return true;
               }
             }
          }
        }
      }
   }
   return false;
}